@media(max-width:767px) {
  #abc-logo {
    display:none;
  }
  .home-nav-item {
    display: block !important;
  }
  .navbar .container {
    margin-left: 1rem;
  }
}
body {
  padding-top: 160px;
}

.app {
    font-family: "Roboto", "Open Sans", sans-serif;
    margin-top: 15px;
    margin-bottom: 60px;
}
  
.app .navbar-brand {
  font-weight: bold;
}
#merchant-code-input {
  border-left: 0 !important;
}

.lander {
  padding: 0px 0 30px 0;
}

.lander h1 {
  font-weight: 600;
}

.lander p {
  color: #999;
}
.border-bottom-1 {
  border-bottom: 1px solid #eee;
}
.home-nav-item {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}